import Card from "../Card/Card";
import "./Result.css";
import {filterByImageRatio} from "../../Utils/Utils";


const Results = ({events}) => {

    function createCard(_event){
        let img;
        let venueName;
        let city;
        let state;

        img = _event.hasOwnProperty('images') ? filterByImageRatio(_event.images) : '';
        venueName = _event._embedded.hasOwnProperty('venues') ? _event._embedded.venues[0].name : '';
        city = _event._embedded.venues[0].hasOwnProperty('city') ? _event._embedded.venues[0].city.name : '';
        state = _event._embedded.venues[0].hasOwnProperty('state') ? _event._embedded.venues[0].state.stateCode : '';

        return <Card
            image={img}
            title={_event?.name || ''}
            date={_event.dates?.start?.localDate || (_event.dates?.start?.dateTime ?? null)}
            address={venueName + ", " + city + ", " +state}
            url={_event?.url ?? ''}
            id={_event.id ?? ''}
            key={_event.id ?? ''}
        />;
    }

    return (
        <div className={"result"}>
            {events && events.map((event) => (
                createCard(event)
            ))}
        </div>
    )};

export default Results;
