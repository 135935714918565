import {NavLink} from "react-router-dom";

export default function Footer(){
    const year = new Date().getFullYear();
return (
        <div className="footer flex flex-col w-full selection:bg-selectionHighLight selection:text-white">
            <div className="flex flex-col bg-secondary py-10 px-4">
                <nav className="flex flex-row font-sans text-lg text-primary gap-6 justify-center py-6">
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/contact" >Contact</NavLink>
                    <NavLink to="/privacy-policy" >Privacy Policy</NavLink>
                </nav>
                <div className="flex flex-row justify-center items-center my-5 text-white font-sans">
                    <p>Copyright © {year} Koble, All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
};
