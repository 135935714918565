import {createContext, useEffect, useState} from "react";
import KEYS from "../keys";
import {DEBUG, encodeQueryData, eventsById, formatDateDisplay, isEmpty} from "./Utils";
import {endOfMonth} from "date-fns/fp";
import {addMonths} from "date-fns";


export const eventsContext = createContext(null);

export const EventsProvider = ({children}) => {

    // These states are shared between the components across the app.
    // They are also use to build the URL for the API call.
    const [events, setEvents] = useState(
        localStorage.getItem('suggestions') ?
            JSON.parse(localStorage.getItem('suggestions')) : {}
    );
    const [search, setSearch] = useState('');
    const [nextPage, setNextPage] = useState('');
    const [eventType, setEventType] = useState(true);
    const [location, setLocation] = useState(
        localStorage.getItem('geolocation') ?
            JSON.parse(localStorage.getItem('geolocation')) : {"Lat":null, "Lng":null,"City":null,"State":null}
    );
    const [formattedLocation, setFormattedLocation] = useState(null);
    const [startEndDates, setStartEndDates] = useState({startDate: null, endDate: null });
    const [loading, setLoading] = useState(false);


    // Listen for changes to the states and save them to local storage.
    useEffect(() => {
        if (location && !isEmpty(location)) localStorage.setItem('geolocation', JSON.stringify(location));
    }, [location]);

    // useEffect(() => {
    //     if (search && search.length > 0) localStorage.setItem('search', search);
    // }, [search]);

    useEffect(() => {
        if (events && !isEmpty(events)) localStorage.setItem('suggestions', JSON.stringify(events));
    }, [events]);

    //  Get location
    useEffect(() => {
        if ((!location || location === 'null' || location === '{}' || location === null || location?.City === null) && !loading) {
            getLocation();
        }
    }, [])


    function getLocation(){
        setLoading(true);
        fetch('https://api.geoapify.com/v1/ipinfo?&apiKey=' + KEYS.geoapify)
            .then(res => res.json())
            .then((result) => {
                let geolocation = {
                    Lat: result.location.latitude,
                    Lng: result.location.longitude,
                    City: result.city.name,
                    State: result.state.name,
                }
                if (DEBUG) console.log('EventContext: Fetching Geolocation: ', geolocation)
                setLocation(geolocation)
                setFormattedLocation(geolocation.City + ", " + geolocation.State)
                setLoading(false)
            }).catch(() => {
            navigator.geolocation.getCurrentPosition((position) => {
                let geolocation = {
                    Lat: position.coords.latitude,
                    Lng: position.coords.longitude,
                    City: '',
                    State: '',
                }
                setLocation(geolocation)
                setLoading(false)
            });
        })
        console.log('EventContext: Fetching Geolocation: ', location)
    }

    const AdvancedURLBuilder = () => {
        let query = encodeQueryData({
            apikey: KEYS.ticketmaster,
            startDateTime: formatDateDisplay(startEndDates?.startDate),
            endDateTime: formatDateDisplay(startEndDates?.endDate || endOfMonth(addMonths(new Date(), 1))),
            classificationName: eventType ? "music" : "sports",
            latlong: location?.Lat && location?.Lat + "," + location?.Lng,
            city: location?.City,
            keyword: search?.length > 0 && search,
            sort: "date,asc",
        })
        return "https://app.ticketmaster.com/discovery/v2/events.json?" + query;
    }

    // Fetch events when the location or event type changes. Runs once on load, after the location is fetched.
    useEffect(() => {
        if (location && !loading){
            setLoading(true);
            const url = AdvancedURLBuilder();
            if (DEBUG) console.log('Fetching with AdvancedURLBuilder: ', url);
            fetch(url)
                .then(res => res.json())
                .then((result) => {
                    if (result) {
                        const uniqueEvents = eventsById(result?._embedded?.events);
                        setEvents(uniqueEvents);
                        setNextPage(result?._links?.next?.href ?? '');
                        setLoading(false);
                    }}
                )
        }
    }, [location, eventType]);

    async function getNextPage(){
        const nextPageUrl = nextPage
        if (nextPageUrl !== null && nextPageUrl !== undefined && nextPageUrl !== ''){
            const url = "https://app.ticketmaster.com/" + nextPageUrl + "&apikey=" + KEYS.ticketmaster;
            if (DEBUG) console.log('getNextPage: url: ', url);
            const response = await fetch(url);
            const result = await response.json();
            const uniqueEvents = eventsById(result?._embedded?.events);
            setEvents({...uniqueEvents, ...events})
            setNextPage(result._links?.next?.href ?? '');
        }
    }

    async function fetchEvents() {
        const url = AdvancedURLBuilder();
        if (DEBUG) console.log('fetchEvents: url:', url);
        fetch(url)
            .then(res => res.json())
            .then((result) => {
                    if (result) {
                        const uniqueEvents = eventsById(result?._embedded?.events);
                        setEvents({...uniqueEvents, ...events});
                        setNextPage(result?._links?.next?.href ?? '');
                        return uniqueEvents;
                    }}
            )
    }

    return (
        <eventsContext.Provider value={{
            events, setEvents,
            fetchEvents, getNextPage,
            nextPage, setNextPage,
            eventType, setEventType,
            location, setLocation, getLocation,
            search, setSearch,
            formattedLocation, setFormattedLocation,
            startEndDates, setStartEndDates,
            loading,
        }}>
            {children}
        </eventsContext.Provider>
    );
};

