import './Card.css';
import {format} from "date-fns";
import {Link} from "react-router-dom";


/**
 * Creates a vertical card with a fix width of 300px for a specific event.
 * The Details button will link to the page detailing the event (Details Page, dynamically created).
 * @param {string} title - event.name.
 * @param {string} image - filterByImageRatio(event.images)
 * @param {string} date - event.dates.start.localDate
 * @param {string} address - event._embedded.venues[0].name + ", " + event._embedded.venues[0].city.name + ", " + event._embedded.venues[0].state.name
 * @param {string} id - event.id
 * @param {string} url - event.url
 * */
const Card = ({title, image, date, address, id, url}) => {
    const eventDate = new Date(date);
    const formattedDate = format(eventDate, 'eee MMMM d, yyyy');


    return (
        <div className="flex flex-col min-h-full min-w-[300px] max-w-[300px] overflow-hidden shadow-secondary rounded-2xl p-3 bg-secondary justify-between ">
            <div className="overflow-hidden rounded-2xl">
                <img className="h-[320px] object-cover pointer-events-none" src={image} alt='' />
            </div>
            <div className="flex flex-col px-1 text-white">
                <div className="font-sans text-lg font-extrabold tracking-tighter text-left text-white my-1">
                    <h3>{title}</h3>
                </div>
            </div>
            <div className="font-sans text-sm font-normal text-left tracking-tight text-white px-1">
            <div className="flex flex-row items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                     className="bi bi-calendar" viewBox="0 0 16 16">
                    <path
                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                </svg>
                <p>{formattedDate}</p>
            </div>
            <div className="flex flex-row gap-2 align-middle">
                <span className="py-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                         className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                    </svg>
                </span>
                <p className="py-1" >{address}</p>
            </div>
            </div>
            <div className="flex flex-row justify-items-start items-center justify-between px-1 ">
                <div className="details underline underline-offset-4 decoration-1 text-white text-lg font-bold cursor-pointer">
                    <Link to={`/events/${id}`} >See Details</Link>
                </div>
                <div className="bg-primary text-white border-0 rounded-lg px-4 py-2 my-2 text-center font-bold font-sans cursor-pointer">
                    <button onClick={() => window.open(url, "_blank")}>Buy Now</button>
                </div>
            </div>
        </div>
    )
}

export default Card;
