import React, {createRef, useEffect, useState} from "react";
import { DateRangePicker, DateRange} from "react-date-range";
import "./Datepicker.css";
import {sizes} from "../../Utils/Utils";

const DateRangeSelector = ({ ranges, onChange, onSubmit, visible, parentPosition, ...rest }) => {

    if(visible){
        document.body.classList.add('active-modal');
    } else {
        document.body.classList.remove('active-modal');
    }

    let pos = parentPosition.current.getBoundingClientRect();
    const ref = createRef();

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
    });

    const [wDimensions, setWDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const detectWindowResize = () => {
        setWDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", detectWindowResize);
        return () => {
            window.removeEventListener("resize", detectWindowResize);
        };
    }, [wDimensions]);


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                visible(false);
                toggleSubmit();
            }
        };

        document.body.addEventListener('click', handleClickOutside, true);
        return () => {
            document.body.removeEventListener('click', handleClickOutside, true);
        };
    });

    const toggleSubmit = () => {
        onSubmit(selectedDateRange);
        visible(false);
        document.body.classList.remove('active-modal');
    };

    const handleSelect = ranges => {
        setSelectedDateRange(ranges.selection);
    };

    const onClickClear = () => {
        setSelectedDateRange({
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        });
        visible(false);
        onSubmit(null);
    };


    function handleSize() {
        if (wDimensions.width < sizes.xlarge) {
            return <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectedDateRange]}
                        rangeColors={["#DD2222"]}
                    />;
        } else {
            return <DateRangePicker
                    onChange={handleSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={[selectedDateRange]}
                    direction="horizontal"
                    rangeColors={["#DD2222"]}
            />;
        }
    }


    return (
            < >
                <div ref={ref} style={{top: (wDimensions.width > sizes.medium ? pos.y + pos.height: 0), left: pos.x}} className="lg:z-[9999] self-center lg:fixed shadow-2xl
                 shadow-secondary inline-block lg:max-w-4xl lg:mt-1 lg:pb-2 rounded-xl overflow-hidden bg-secondary border-0 outline-0 ">
                    {handleSize()}
                    <div className=" flex flex-col lg:flex-row w-full text-right rdr-buttons-position lg:mt-4 lg:mb-2 my-1 font-medium lg:px-4 gap-1">
                        <button
                            className="btn bg-background text-white rounded py-1 lg:px-4 lg:mx-2 w-full hover:bg-highlight focus:bg-primary"
                            onClick={()=>{
                                visible(false);
                                document.body.classList.remove('active-modal');
                            }}
                        >
                            Close
                        </button>

                        <button
                            className="btn bg-background text-white rounded py-1 lg:px-4 lg:mx-2 w-full hover:bg-highlight focus:bg-primary"
                            onClick={onClickClear}
                        >
                            Clear
                        </button>
                        <button
                            className="btn bg-background text-white rounded py-1 lg:px-4 lg:mx-2 w-full hover:bg-highlight focus:bg-primary"
                            onClick={toggleSubmit}
                        >
                            Apply
                        </button>

                    </div>
                </div>
            </>
    );
};
export default DateRangeSelector;

