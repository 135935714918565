import ticketMaster from "../images/ticketmaster.png";
import Stubhub from "../images/Stubhub.png";
import Hipster from "../images/Hipster.jpg";
import Ticket from "../images/Ticket.svg";
import SearchIcon from "../images/Search.svg";
import Music from "../images/Music.svg";
import AppStore from "../images/AppStore.png";
import PlayStore from "../images/PlayStore.png";
import logo from "../images/logo_medium.png";
import {Link} from "react-router-dom";
import Carousel from "../Components/Carousel/Carousel";
import {Canvas} from "@react-three/fiber";
import {Phone} from "../Components/iPhone";
import {Suspense} from "react";
import ErrorBoundary from "../Components/errorBoundary";


const Home = () => {
    document.title = "Koble | Home";
    document.description = "Connecting fans with bands";
    document.contents = "Connecting fans with bands";


    return (
        <>
            <div className="home flex flex-col w-full h-fit selection:bg-selectionHighLight selection:text-white">
                {/*Landing Page*/}
                <section>
                    <div className="hero_title flex flex-col w-full justify-center items-center gap-y-12 min-h-screen ">
                        {/*<h1 className="text-[8rem] lg:text-[12rem] font-extrabold text-white drop-shadow-bright font-bahnschrift lg:font-bahnschrift md:font-bahnschrift sm:font-bahnschrift animate-fadeIn">koble.</h1>*/}
                        <img src={logo} className={'h-24 lg:h-36 animate-fadeIn'} alt={'koble logo'}/>
                        <hr className="w-[60px] border-t-primary border-t-2 rounded " />
                        <p className="text-white font-semibold text-xl font-sans">Connecting fans with bands</p>
                        <Link to={'/about'}>
                            <button className="btn-red w-fit rounded-lg">LEARN MORE</button>
                        </Link>
                    </div>
                </section>

                {/*Carousel*/}
                <section className={'lg:my-8'}>
                    <ErrorBoundary fallback={null}>
                        <Carousel />
                    </ErrorBoundary>
                </section>

                <section className={'sm:h-10 md:h-20 lg:h-40 bg-transparent'}/>

                {/* links to the App*/}
                <section className="flex flex-col grow font-extrabold text-4xl text-center items-center w-full bg-transparent my-6 p-6 space-y-4">
                    <section className="text-white font-extrabold text-2xl lg:text-4xl text-center h-20 w-fit">
                        <h1 className={''}>Download Koble on the Google Play Store</h1>
                    </section>

                    {/*3d Phone*/}
                    <div className={'h-[600px] w-full'}>
                        <Suspense fallback={null}>
                            <Canvas className={'w-full'}>
                                {/*<Environment preset={'city'} />*/}
                                <Phone />
                                <ambientLight intensity={4} />
                                <pointLight position={[10, 5, 10]} />
                            </Canvas>
                        </Suspense>
                    </div>

                    <div className="flex flex-col lg:flex-row w-full h-full gap-10 justify-center">
                        <img src={PlayStore} className={'max-h-[50px] max-w-[150px] lg:max-h-[75px] lg:max-w-[225px] drop-shadow-fade cursor-pointer hover:drop-shadow-bright self-center'}
                             alt={'Google Play Store'} onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.koble.koble')}/>

                        {/* <img src={AppStore} className={'max-h-[50px] max-w-[150px] lg:max-h-[75px] lg:max-w-[225px] drop-shadow-fade cursor-pointer hover:drop-shadow-bright self-center'}
                             alt={'Apple App Store'} onClick={()=>window.open('https://apps.apple.com/ca/app/koble/id6444964422')}/> */}
                    </div>
                </section>
                <section className={'sm:h-10 md:h-20 lg:h-40 bg-transparent'}/>

                {/*Features Section*/}
                <section className="flex flex-col lg:flex-row gap-4 w-full text-white bg-background p-8 justify-center align-middle items-center">
                    <div className="flex  lg:justify-end lg:w-full">
                        <img src={Hipster} alt="ticketmaster" className="w-[550px] rounded-2xl" />
                    </div>
                    <div className="flex flex-col w-full px-8 gap-6 font-sans md:justify-center lg:justify-end lg:w-full">
                        <div className="row">
                            <h3 className="text-2xl font-bold">MAKE MEMORIES THAT LAST A LIFETIME.</h3>
                        </div>
                        <div className="row">
                            <div className="header_container flex flex-row gap-4">
                                <img src={SearchIcon} alt="ticket" className="h-6"/>
                                <h3 className="text-xl font-bold">Find Concerts</h3>
                            </div>
                            <p className="text-lg text-subtle font-medium">Your favourite concert is just a search away.</p>
                        </div>
                        <div className="row">
                            <div className="header_container flex flex-row gap-4">
                                <img src={Ticket} alt="ticket" className="h-6"/>
                                <h3 className="text-xl font-bold">Buy Tickets</h3>
                            </div>
                            <p className="text-lg text-subtle font-medium">Tickets for tonight, tomorrow night, or for way too long in the</p>
                            <p className="text-lg text-subtle font-medium">future that you'll forget... we have it all.</p>
                        </div>
                        <div className="row">
                            <div className="header_container flex flex-row gap-4">
                                <img src={Music} alt="ticket" className="h-6"/>
                                <h3 className="text-xl font-bold">Explore Sports</h3>
                            </div>
                            <p className="text-lg text-subtle font-medium">Koble also offers sports tickets!</p>
                            <p className="text-lg text-subtle font-medium">Check out a beloved game, or find a new one.</p>
                        </div>
                    </div>
                </section>

                {/*Partners*/}
                <section className={'sm:h-10 md:h-20 lg:h-40 bg-transparent'}/>
                <section className="flex flex-col font-extrabold text-white text-4xl font-sans items-center gap-y-10 py-10 h-fit bg-background">
                    <h2>Official Partners:</h2>
                    <a className={'TicketMaster bg-transparent'} href="https://ticketmaster.evyy.net/c/3130879/320146/4272?u=http%3A%2F%2Fwww.ticketmaster.com" target="_blank" rel="noreferrer">
                        <img src={ticketMaster} alt="Ticket Master"/>
                    </a>
                    <a className={'StubHub bg-transparent'} href="https://prf.hn/click/camref:1101lkcuw/%5Bp_id:1101l431847%5D" target="_blank" rel="noreferrer">
                        <img src={Stubhub} alt="Stub Hub"/>
                    </a>
                </section>
                <section className={'sm:h-10 md:h-20 lg:h-40 bg-transparent'}/>
            </div>
        </>
    );
};
export default Home;


