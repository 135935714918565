import 'tailwindcss/tailwind.css'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import React, { Suspense } from "react";
// import About from "./Pages/About";
// import Contact from "./Pages/Contact";
// import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import PrivacyPolicyApp from "./Pages/PrivacyPolicyApp";
import Details from "./Pages/Details";
import Home from "./Pages/Home";
import Events from "./Pages/Events";
import HomeLayout from "./Pages/HomeLayout";
// import {EventsProvider } from "./Utils/eventContext";
import {Page404} from "./Pages/Page404";
import SuspensePage from "./Pages/SuspensePage";


// const Details = React.lazy(() => import('./Pages/Details'));
const About = React.lazy(() => import('./Pages/About'));
const Contact = React.lazy(() => import('./Pages/Contact'));
const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy'));
const PrivacyPolicyApp = React.lazy(() => import('./Pages/PrivacyPolicyApp'));


// https://stackoverflow.com/questions/60946584/react-use-context-cannot-destructure-property-currentchatbotinedit-of-object

function App() {

    // const [events, setEvents] = useState({});
    // const eventsValue = () => {return {events, setEvents}};

  return (
      <BrowserRouter>
          <Suspense fallback={<SuspensePage />}>
            {/*<EventsProvider value={eventsValue}>*/}
                <Routes>
                    <Route path="/" element={<HomeLayout />}>
                        <Route index element={<Home />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/events/:id" element={<Details />} />
                        <Route path="*" element={<Page404 />}/>
                    </Route>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/koble-app-privacy-policy" element={<PrivacyPolicyApp />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            {/*</EventsProvider>*/}
          </Suspense>
      </BrowserRouter>
  );
}

export default App;

