import { Outlet } from 'react-router-dom';
import StyledNavbar from "../Components/StyledNavbar";
import Hero from "../images/HeroImage2.jpeg";
import Footer from "../Components/Footer";

const HomeLayout = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${Hero})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                minHeight: '60vh',
                minWidth: '100%',
            }}
            className="bg-no-repeat bg-cover bg-center w-full h-full">
            <StyledNavbar />
            <Outlet />
            <Footer />
        </div>
    );
};
export default HomeLayout;
