import React from 'react';
import Hero from "../images/HeroImage2.jpeg";
import Footer from "../Components/Footer";
import {Link} from "react-router-dom";

export function Page404() {
    return (
        <div
            style={{
                backgroundImage: `url(${Hero})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                minHeight: '60vh',
                minWidth: '100%',
            }}
            className="bg-no-repeat bg-cover bg-center w-full h-screen">
            <div className="flex flex-col h-screen items-center justify-center place-content-center text-white space-y-12">
                <h1 className="text-4xl font-bold">Oops!</h1>
                <p className="text-2xl">We couldn't find the event you were looking for.</p>
                <div className="bg-primary text-white border-0 rounded-lg px-4 py-2 my-2 text-center font-bold font-sans cursor-pointer">
                    <Link to="/">Home</Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
