import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar as CalendarIcon, faXmark, faLocationDot } from "@fortawesome/free-solid-svg-icons"
import {useContext, useRef, useState} from 'react';
import DateRangeSelector from "../DateRange/DateRangeSelector";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import {eventsContext} from "../../Utils/eventContext";
import SearchHelper from "./SearchHelper";
import {sizes} from "../../Utils/Utils";
import DoubleButton from "../DoubleButton";


const Search = (props) => {
    const {setShow} = props;
    const {
        fetchEvents,
        location, setLocation, getLocation,
        formattedLocation,
        search, setSearch,
        startEndDates, setStartEndDates
    } = useContext(eventsContext);
    const [datePicker, showDatePicker] = useState(false);
    const anchor = useRef(null);
    const suggestionRef = useRef(null);

    async function getTicketMasterApi() {
        if (formattedLocation !== location.city) setLocation({"Lat":null, "Lng":null,"City": formattedLocation});
        fetchEvents();
        setShow(false);
    }


    return (
        <>
            <div ref={anchor}
                 className="flex flex-col w-full 2xl:flex-row grow 2xl:min-w-[750px] 2xl:max-w-4xl 2xl:overflow-hidden 2xl:rounded-lg bg-secondary items-center shadow-2xl shadow-highlight/5">
                <div className="flex flex-row justify-between items-center 2xl:min-w-[220px] w-full 2xl:mx-2 2xl:p-2 p-4">

                    {/* Keyword Search Input */}
                    <input ref={suggestionRef} className="2xl:min-w-[160px] w-full bg-transparent text-white font-normal text-sm align-middle font-sans placeholder:text-subtle focus:outline-none"
                           type="text"
                           placeholder="Search for an event"
                           value={search}
                           onChange={(e) => {
                               setSearch(e.target.value);
                           }}
                    />
                    <FontAwesomeIcon
                        className="flex flex-row justify-items-center items-center text-white text-sm align-middle font-sans cursor-pointer hover:text-primary"
                        icon={faXmark}
                        onClick={() => {
                            setSearch('');
                        }}
                    />
                </div>

                {/* Date Range Button */}
                <div className="w-full p-2 2xl:p-0 min-w-[140px] 2xl:max-w-[160px] 2xl:mx-2" id="date-box">
                    <button
                        className="flex flex-row bg-transparent text-white gap-3 w-full h-full items-center text-sm align-left hover:cursor-pointer focus:outline-none"
                        onClick={() => {
                            showDatePicker(!datePicker);
                        }}
                    >
                        <FontAwesomeIcon icon={CalendarIcon} className="text-white hover:text-primaryHighlight p-2" />
                        {startEndDates?.startDate && startEndDates?.endDate ? (
                            <span className="text-white font-normal text-sm align-middle font-sans">
                                {format(startEndDates.startDate, "MMM d")} - {format(startEndDates.endDate, "MMM d")}
                            </span>
                        ) : (
                            <span className="text-subtle font-normal text-sm align-middle font-sans truncate">
                                Select Date Range
                            </span>
                        )}
                    </button>
                </div>
                {window.innerWidth < sizes.xxlarge && (datePicker && <DateRangeSelector onSubmit={setStartEndDates} visible={showDatePicker} parentPosition={anchor}/>)}

                {/* Location Search */}
                <div className="location-search flex flex-row w-full p-4 2xl:p-2  min-w-[140px] 2xl:max-w-[200px] gap-6">
                    <FontAwesomeIcon icon={faLocationDot} className={"location-button text-primary hover:text-accent"} onClick={getLocation}/>
                    <SearchHelper />
                </div>

                {window.innerWidth < sizes.xxlarge && <DoubleButton />}

                {/* Search Button */}
                <Link
                    className="flex bg-primary text-white 2xl:my-0 px-4 p-2 w-full 2xl:w-fit font-sans font-bold 2xl:font-normal hover:bg-primaryHighlight 2xl:rounded-none rounded-md"
                    onClick={getTicketMasterApi}
                    to={{pathname: "/events",}}
                >
                    Search
                </Link>

                {/* Date Range Selector Menu */}
                {window.innerWidth > sizes.xxlarge && (datePicker && <DateRangeSelector onSubmit={setStartEndDates} visible={showDatePicker} parentPosition={anchor}/>)}
            </div>
        </>

    )
}
export default Search;
