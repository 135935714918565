import { useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";


/**
 * Creates an accordion component.
 * @param title - The title of the accordion.
 * @param content - The content to be displayed when the accordion is open.
 * @returns {JSX.Element} - Returns an accordion component.
 * */
const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-item">
            <div className="accordion-title flex flex-row gap-6 bg-purpleNight w-fit px-6 py-2 rounded-lg" onClick={() => setIsActive(!isActive)}>
                <h2 className='font-extrabold font-sans text-lg tracking-wide'>{title}</h2>
                <div className="accordion-icon place-self-center">
                    {isActive ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </div>
            </div>
            {isActive && <div className="accordion-content">{content}</div>}
        </div>
    );
};

export default Accordion;
