/**
 *  @fileoverview Events.js - Page for displaying events.
 *  @version 1.0.0
 * */


import Results from "../Components/Results/Results";
import {eventsContext} from "../Utils/eventContext";
import {useContext, useEffect, useState} from "react";
import ErrorBoundary from "../Components/errorBoundary";
import {Page404} from "./Page404";
import {DEBUG} from "../Utils/Utils";


//  todo need to do something if no location is given. Maybe a default location?
//  todo http://localhost:3000/events/jhghkghg gives: https://app.ticketmaster.com/discovery/v2/events/jhghkghg.json?apikey=n77vMMJ41XXsKyqG9OCpQyoT75u6Wi2R
//  todo handle different types of responses differently ex: 429 and 404.

export default function Events() {
    document.title = "Koble - Events";
    const {events, getNextPage, nextPage, loading} = useContext(eventsContext);
    const [listOfEvents, setListOfEvents] = useState([]);

    useEffect(() => {
        try {
            const arr = Object.values(events)
            const e = localStorage.getItem("suggestions")?.length > 0 && JSON.parse(localStorage.getItem("suggestions"))
            if (arr.length > 0){
                setListOfEvents(arr)
            } else {
                setListOfEvents(Object.values(e))
            }
        } catch (e) {
            console.log("Events: Error parsing events: ", e)
        }
        if (DEBUG) console.log('Events: listOfEvents:  ', listOfEvents)
    }, [events])

    if (DEBUG) console.log('Events: listOfEvents: ', listOfEvents)
    listOfEvents.sort((a, b) => {
        return new Date(a.dates.start.localDate) - new Date(b.dates.start.localDate);
    });
    if (DEBUG) console.log('Events: listOfEvents: SORTED:', listOfEvents)


    return (
        <ErrorBoundary fallback={Page404}>
            <div className="events">
                <div className="flex flex-col bg-transparent w-full h-full min-h-screen py-14">
                    {(listOfEvents.length > 0) ?
                        <div>
                            <h1 className="text-2xl text-center text-white mx-10">Results: {listOfEvents.length}</h1>
                            <Results events={listOfEvents} />
                        </div>:
                        <div className="flex min-h-screen">
                            <h1 className="flex w-full h-full text-4xl font-bold drop-shadow-bright text-center justify-center items-center text-white">No events found</h1>
                        </div>
                    }
                    {loading && <h1 className="text-2xl text-center text-white mx-10">Finding Events</h1>}
                </div>
                <div className="flex flex-row justify-center items-center align-middle py-10">
                    {nextPage && <button className="btn-red w-fit" onClick={getNextPage}>Next Page</button>}
                </div>
            </div>
        </ErrorBoundary>
    );
}
