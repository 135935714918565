import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import KEYS from "../../keys";
import {useContext, useEffect, useRef, useState} from "react";
import {eventsContext} from "../../Utils/eventContext";


export default function SearchHelper(){
    const {location, setLocation, formattedLocation, setFormattedLocation} = useContext(eventsContext);
    const parentPosition = useRef(null);
    const [position, setPosition] = useState();
    const [expanded, setExpanded] = useState(false);
    const ref = useRef();


    useEffect(() => {
            if (formattedLocation) parentPosition.current.value = formattedLocation;
            else {
                let local = localStorage.getItem('geolocation');
                if (local) parentPosition.current.value = JSON.parse(local).City + ", " + (JSON.parse(local).State ?? '');
            }
            if (location) parentPosition.current.value = location.City + ", " + (location.State ?? '');
    }, [location]);


    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesAutocompleteService({
        apiKey: KEYS.google,
        options: {
            types: ['(regions)'],
            componentRestrictions: { country: ["ca","us"] },
        },
        language: 'en',
    });

    const handlePlace = (place) => {
        setLocation({'Lat': place.geometry.location.lat(), 'Lng': place.geometry.location.lng(), 'City': place.address_components[0].long_name});
        return {'Lat': place.geometry.location.lat(), 'Lng': place.geometry.location.lng(), 'City': place.address_components[0].long_name}
    }


    const handleSelect = (placeId) => {
        try {
            placesService.getDetails({ placeId }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    // setFormattedLocation(place.formatted_address);
                    setFormattedLocation(place.address_components[0].long_name);
                    setLocation(handlePlace(place));
                }
            });
        } catch (e) {
            console.log("Error: SearchHelper: HandleSelect:", e);
        } finally {
            setExpanded(false);
        }
    };

    const handleInput = (e) => {
        setFormattedLocation(e.target.value);
        getPlacePredictions({ input: e.target.value });
    };

    function renderItem(item, index, arrLength) {

        return (
            <div className="match" key={item.place_id} >
                {index + 1 === arrLength ?
                    <div
                        className="description bg-secondary py-2 px-4 text-white cursor-pointer hover:bg-backgroundThree truncate"
                        onClick={() => handleSelect(item.place_id)}
                    >{item.description}</div>:
                    <div
                        className="description bg-secondary py-2 px-4 text-white border-b-2 border-purpleNight cursor-pointer hover:bg-backgroundThree truncate"
                        onClick={() => handleSelect(item.place_id)}
                    >{item.description}</div>
                }
            </div>
        );
    }

    function expand() {
        const pos = parentPosition.current.getBoundingClientRect();
        setPosition({top: pos.top, left: pos.left, width: pos.width, height: pos.height});
        setExpanded(true);
    }

    return (
        <>
         <input
             className="bg-transparent text-subtle text-sm font-sans outline-none w-full"
             type="text"
             placeholder={(formattedLocation || 'Search Location')}
             // value={(formattedLocation || '')}
             onChange={(evt) => {
                 handleInput(evt);
                 expand();
             }}
             ref={parentPosition}
             onClick={() => {
                 expand();
             }}
             onFocus={() => {
                expand()
             }}
         />
            {expanded && (
                <div ref={ref} className="flex flex-col md:h-fit md:w-fit absolute rounded-xl overflow-hidden shadow-2xl shadow-secondary z-[9999]" style={{top: position?.top + 30, left: position?.left, maxWidth: position?.width * 1.5}}>
                    {!isPlacePredictionsLoading && placePredictions.map((item, index) => renderItem(item, index, placePredictions.length)) }
                </div>
            )}
        </>
    )
}
