/**
 *  @file       StyledNavbar.js
 *  @brief      This component is used to display the navbar of the application.
 *  @details    It is used in the header of the application.
 * */

import { NavLink } from 'react-router-dom';
import Search from "./Search/Search";
import logo from ".././images/logo.png"
import ErrorBoundary from "./errorBoundary";
import {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import SwitchButton from "./SwitchButton";
import {sizes} from "../Utils/Utils";



const StyledNavbar = () => {

    const ref = useRef(null);
    const [show, setShow] = useState(false);
    const [wDimensions, setWDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const detectWindowResize = () => {
        setWDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", detectWindowResize);
        return () => {
            window.removeEventListener("resize", detectWindowResize);
        };

    }, [wDimensions]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false);
            }
        };

        document.body.addEventListener('touchend', handleClickOutside, true);
        document.body.addEventListener('click', handleClickOutside, true);
        return () => {
            document.body.removeEventListener('touchend', handleClickOutside, true);
            document.body.removeEventListener('click', handleClickOutside, true);
        };
    });


    return (
        <ErrorBoundary>
            <div ref={ref} className="flex flex-col justify-between align-middle items-center 2xl:h-40 2xl:flex-row 2xl:gap-12 w-full z-[9999] bg-secondary 2xl:bg-transparent">
                <div className="flex flex-row justify-between align-middle items-center w-full 2xl:w-fit 2xl:px-10">
                    <NavLink className="" to="/">
                        <img src={logo} className="w-[50px] min-w-[50px] px-1" alt="logo" />
                    </NavLink>

                    {wDimensions.width <= sizes.xxlarge &&
                            <FontAwesomeIcon
                                icon={faBars}
                                className="text-3xl text-white 2xl:hidden px-3 py-2"
                                onClick={() => setShow(!show)}
                            />
                    }
                </div>

                {wDimensions.width >= sizes.xxlarge ?  // for mobile, we want search to be below the nav.
                    <>
                        <div className={'flex flex-row h-[40px] gap-2'}>
                            <Search setShow={setShow} />
                            <SwitchButton />
                        </div>
                        <nav className="flex flex-col w-full 2xl:flex-row font-sans text-xl text-white 2xl:gap-4 bg-secondary items-center 2xl:w-fit 2xl:bg-transparent border-b-secondary md:border-0 pr-5">
                            <NavLink to="/events" className="p-4 w-full text-left hover:bg-highlight 2xl:hover:bg-transparent">Events</NavLink>
                            <NavLink to="/about" className="p-4 w-full text-left hover:bg-highlight 2xl:hover:bg-transparent">About</NavLink>
                            <NavLink to="/contact" className="p-4 w-full text-left hover:bg-highlight 2xl:hover:bg-transparent">Contact</NavLink>
                        </nav>
                    </>:
                    show &&
                    <div className={'w-full px-1'}>
                        <nav
                            onClick={() => {
                                setShow(false);
                            }}
                            className="flex flex-col 2xl:flex-row font-sans text-xl text-white bg-secondary w-full items-center border-b-secondary">
                            <NavLink to="/" className="p-4 w-full text-left hover:bg-highlight 2xl:hover:bg-transparent">Home</NavLink>
                            <NavLink to="/events" className="py-2 px-4 w-full text-left hover:bg-highlight 2xl:hover:bg-transparent">Events</NavLink>
                            <NavLink to="/about" className="py-2 px-4 w-full text-left hover:bg-highlight 2xl:hover:bg-transparent">About</NavLink>
                            <NavLink to="/contact" className="py-2 px-4 w-full text-left hover:bg-highlight 2xl:hover:bg-transparent">Contact</NavLink>
                        </nav>
                        <Search setShow={setShow} />
                    </div>
                }
            </div>
        </ErrorBoundary>
    );
};
export default StyledNavbar;


