import React, {useContext, useEffect, useRef} from 'react';
import {eventsContext} from "../Utils/eventContext";
// import './SwitchButton.css';

function DoubleButton(props) {
    const {eventType, setEventType} = useContext(eventsContext);
    const sportsRef = useRef();
    const musicRef = useRef();

    useEffect(() => {
        eventType ? musicRef.current.classList.add('bg-primary') : musicRef.current.classList.remove('bg-primary');
        eventType ? sportsRef.current.classList.remove('bg-primary') : sportsRef.current.classList.add('bg-primary');
    }, [eventType]);
    // console.log('EventsType: doubleButton', eventType)

    return (
        <div className={'w-full bg-secondary rounded-md my-3 flex flex-row outline outline-1 outline-primary mx-1'}>
            <button ref={musicRef} className={'w-full p-2 text-white text-bold rounded-l-md'} onClick={() => {
                setEventType(true)
            }}>Music</button>
            <button ref={sportsRef} className={'w-full p-2 text-white text-bold rounded-r-md '} onClick={() => {
                setEventType(false)
            }}>Sports</button>
        </div>

    );
}

export default DoubleButton;
